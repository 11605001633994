<template>
  <div class="app-container">
    <CrudTable
      entity="MyComputerAccess"
      :columns="columns"
      :permission-code="'MyComputerAccess'"
      :allow-create="false"
    />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter } from '@/utils/filters';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        {
          field: 'code',
          label: 'computerAccess.code'
        },
        {
          field: 'ipAddress',
          label: 'computerAccess.ip'
        },
        {
          field: 'createdAt',
          label: 'computerAccess.createdAt',
          type: 'dateTime',
          filter: { type: 'none' }
        },
        {
          field: 'accessYN',
          label: 'computerAccess.accessYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        }
      ]
    };
  }
};
</script>

<style scoped></style>
